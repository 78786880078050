import axios from 'axios';
import getApiUrl from '../../helpers/apiUrls'
import { authHeader } from '../../helpers/authHeader';
import utilCommon, {buildQueryString} from '../../helpers/utilCommon'
import * as actions from '../../store/customer/actions'
import * as commonAction from '../../store/common/actions'
import * as type from '../../store/customer/actionTypes'
import * as shipmentType from '../../store/shipment/actionTypes'
import * as quoteType from '../../store/quote/actionTypes'
import shipmentService from './shipmentService'
import leadService from './leadService'
import lodash from 'lodash';
import {SET_CUSTOMER_EMAIL_TEMPLATES} from "../../store/customer/actionTypes";
import * as commonActions from "@store/common/actions";
import {getCustomerStoreCreditBalance} from "@services/storeCreditService";

// add customer
export const addCustomer = (dispatch, lang, dictionary, inputDTO, history) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'addCustomer');
    if (!inputDTO.customerUuid) {
        dispatch(commonAction.setLoader(true))
    } else {
        dispatch(actions.setLoader(true))
    }

    axios[inputDTO.customerUuid ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            if (!inputDTO.customerUuid)
                dispatch(commonAction.setLoader(false))
            else
                dispatch(actions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                if (!inputDTO.customerUuid) {
                    dispatch({ type: quoteType.SET_CUSTOMER, selectedCustomer: response.data.content.customerId })
                    history.push('/home/customer-management'+'/'+response.data.content.customerUuid)
                }

                if (lodash.get(inputDTO, 'getCustomerData', 0) === 1) {
                    getCustomerData(dispatch, lang, dictionary, inputDTO);
                }

                if (inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            if (!inputDTO.customerUuid)
                dispatch(commonAction.setLoader(false))
            else
                dispatch(actions.setLoader(false))
        });
};

// get customer logs
export const getCustomerLogs = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'customerLogs');
    let queryString = utilCommon.convertObjectToQueryString(inputDTO);
    url = url + `?${queryString}`;
    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_CUSTOMER_LOGS, logs: [], totalResults: 0 })
                dispatch({ type: type.SET_CUSTOMER_LOGS, logs: response.data.content.activityLog, totalResults: response.data.content.totalResults })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// get customer master data
export const getMasterData = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };

    // Countries
    let url = getApiUrl('customer', 'getCountry');
    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_COUNTRIES, countries: response.data.content.countryList })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {});

    // States
    let url1 = getApiUrl('customer', 'getState');
    axios.get(url1, params)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_STATES, states: response.data.content.stateList })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {});
    // timezones

    let url2 = getApiUrl('customer', 'getTimezone');
    axios.get(url2, params)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_TIMEZONES, timezones: response.data.content.timeZoneList.map((tz) => Object.assign({}, tz, {name: tz.name.split('0) ')[1]})) })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {});

    const params3 = { headers: authHeader() };
    const url3 = getApiUrl('customer', 'customerMaster');
    axios.get(url3, params3)
        .then((response) => {

            if (response.data.success) {
                dispatch({ type: type.SET_CUSTOMER_MASTER, customerMasterData: response.data.content.compositeCustomerDataList })
            }
        })
        .catch((err) => {});
    const params5 = { headers: authHeader() };
    const url5 = getApiUrl('customer', 'agents');
    axios.get(url5 + "?status=1&sortColumn=name&sortOrder=asc" + (lodash.get(inputDTO, 'includeAgentLeadEnableStatus', false) ? "&includeLeadEnable=1" : ""), params5)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_ACTIVE_AGENTS, activeAgents: response.data.content.userList })
            }
        }).catch((err) => {});
};

// get customer list
export const getCustomers = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'customer');
    if (inputDTO.advancedSearch) {
        Object.keys(inputDTO).filter(x => x != 'advancedSearch' && x != 'formattedPhoneNumber').map((y, index) => {
            if (Array.isArray(inputDTO[y])) {
                inputDTO[y] = JSON.stringify(inputDTO[y])
            }

            if (index == 0) {
                url = url + '?' + y + '=' + inputDTO[y]
            } else {
                url = url + '&' + y + '=' + inputDTO[y]
            }
        })

        url = url + '&advancedSearch=1'
    } else {
        let col = inputDTO.sortColumn
        if (inputDTO.sortColumn == 'customerFirstName' || inputDTO.sortColumn == 'customerLastName') {
            col = 'customerName'
        }
        url = url + '?pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber + '&sortColumn=' + col + '&sortOrder=' + inputDTO.sortOrder
        if (inputDTO.keyword) {
            url = url + '&keyword=' + (inputDTO.keyword.includes('&') ? inputDTO.keyword.split('&')[0] + encodeURIComponent('&') + inputDTO.keyword.split('&')[1] : inputDTO.keyword)
        }
        if (inputDTO.registrationDateFrom) {
            url = url + '&registrationDateFrom=' + inputDTO.registrationDateFrom
        }
        if (inputDTO.registrationDateTo) {
            url = url + '&registrationDateTo=' + inputDTO.registrationDateTo
        }
        if (inputDTO.customerShippingFrequency && inputDTO.customerShippingFrequency!=0) {
            url = url + '&customerShippingFrequency=' + inputDTO.customerShippingFrequency
        }
        if (inputDTO.shipmentStatus && inputDTO.shipmentStatus!=0) {
            url = url + '&customerShipmentStatus=' + inputDTO.shipmentStatus
        }
        if(inputDTO.customerPaymentType && inputDTO.customerPaymentType!=0){
            url = url + '&customerPaymentType=' + inputDTO.customerPaymentType
        }
        if(inputDTO.customerSource && inputDTO.customerSource!=0){
            url = url + '&customerSource=' + inputDTO.customerSource
        }
        if (inputDTO.billingAdjustment && inputDTO.billingAdjustment != "2") {
            url = url + '&hasBillingAdjustment=' + inputDTO.billingAdjustment
        }
        if (inputDTO.customerStatus && inputDTO.customerStatus != "2") {
            url = url + '&customerStatus=' + inputDTO.customerStatus
        }
        if (inputDTO.agentUserUuid && inputDTO.agentUserUuid.filter(x => x != "0").length > 0) {
            url = url + '&agentUserUuid=' + JSON.stringify(inputDTO.agentUserUuid.filter(x=>x!=0))
        }
        if(inputDTO.redirectionFilters && inputDTO.redirectionFilters.redirection){
            if (inputDTO.redirectionFilters.date && inputDTO.redirectionFilters.date!='0') {
                let createdDate = utilCommon.getDateRange(1,inputDTO.redirectionFilters.date,inputDTO.redirectionFilters.fromDate,inputDTO.redirectionFilters.toDate)
                url = url + '&registrationDateFrom=' + createdDate.startDate
                url = url + '&registrationDateTo=' + createdDate.endDate
            }
            if( inputDTO.redirectionFilters.customerSource){
                url = url + '&customerSource=' + inputDTO.redirectionFilters.customerSource
            }
            if( inputDTO.redirectionFilters.customerShippingFrequency){
                url = url + '&customerShippingFrequency=' + inputDTO.redirectionFilters.customerShippingFrequency
            }
            if (inputDTO.redirectionFilters.agentUserUuid && inputDTO.redirectionFilters.agentUserUuid.filter(x => x != "0").length > 0) {
                url = url + '&agentUserUuid=' + JSON.stringify(inputDTO.redirectionFilters.agentUserUuid.filter(x=>x!=0))
            }
        }

        if (lodash.get(inputDTO, 'customerId')) {
            url = url + '&customerId=' + JSON.stringify(inputDTO.customerId)
        }
    }

    if (!lodash.get(inputDTO, 'liveCustomers', false)) {
        dispatch(commonAction.setLoader(true));
    }

    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false));
            if (response.data.success) {
                if (!lodash.get(inputDTO, 'liveCustomers', false)) {
                    if (inputDTO.keyword && response.data.content.customerList && response.data.content.customerList.length == 0) {
                        utilCommon.showNotification(9001, dictionary, lang);
                    }

                    if (inputDTO.keyword) {
                        dispatch({type: type.SET_SEARCH, keyword: inputDTO.keyword});
                    } else {
                        dispatch({type: type.SET_SEARCH, keyword: null});
                    }

                    dispatch(actions.setCustomers(
                        response.data.content.customerList,
                        response.data.content.count,
                        false,
                        url,
                        inputDTO.advancedSearch ? 1 : 0,
                        inputDTO.advancedSearch ? inputDTO : {}
                    ));
                } else {
                    dispatch(actions.setOnlineCustomers(
                        response.data.content.customerList,
                        response.data.content.count
                    ))
                }
            } else {
                response.data.errors.map((error) => {
                    if (!lodash.get(inputDTO, 'liveCustomers', false)) {
                        utilCommon.showNotification(error, dictionary, lang);
                    }
                })
                if (!lodash.get(inputDTO, 'liveCustomers', false)) {
                    dispatch(actions.setCustomers([], 0, false, url));
                }

                dispatch(actions.setOnlineCustomers([], 0));
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// update Detailed Information
export const updateDetailedInformation = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'detailInfo');
    dispatch(actions.setLoader(true))
    axios[inputDTO.customerUuid ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            dispatch(actions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getCustomerData(dispatch, lang, dictionary, inputDTO)
                if (inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(actions.setLoader(false))
        });
};

// add or update address
export const addOrUpdateAddress = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'address');
    dispatch(commonAction.setLoader(true))
    axios[inputDTO.customerAddressUuid ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getAddresses(dispatch, lang, dictionary, { ...inputDTO, pageNumber: 1, pageSize: 10, sortColumn: 'addedDate', sortOrder: 'desc' });
                dispatch({
                    type: type.UPDATE_ADDRESS_PAGING,
                    addressPageNumber: 1,
                    addressPageSize: 10,
                    addressSortColumn: 'addedDate',
                    addressSortOrder: 'desc'
                })
                if (inputDTO.isPrimary == 1) {
                    getCustomerData(dispatch, lang, dictionary, inputDTO)
                }

                if (inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
                }

            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// add or update product
export const addOrUpdateProduct = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'product');
    dispatch(commonAction.setLoader(true))
    axios[inputDTO.customerProductUuid ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
                getProducts(dispatch, lang, dictionary, { ...inputDTO, pageNumber: 1, sortColumn: 'addedDate', sortOrder: 'desc', pageSize: 10 });
                dispatch({
                    type: type.UPDATE_PRODUCT_PAGING, productPageNumber: 1,

                    productPageSize: 10,
                    productSortColumn: 'addedDate',
                    productSortOrder: 'desc'
                })

                if (inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// add or update task
export const addOrUpdateTask = (dispatch, lang, dictionary, inputDTO, flag = false) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'task');
    dispatch(commonAction.setLoader(true))
    axios[inputDTO.userTaskUuid ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                if (!flag) {
                    getTasks(dispatch, lang, dictionary, { ...inputDTO, sortColumn: 'dueDateTime', sortOrder: 'asc' });
                }
                else {
                    shipmentService.getTasks(dispatch, lang, dictionary, { customerUuid: inputDTO.customerUuid, shipmentUuid: inputDTO.shipmentUuid, sortColumn: 'dueDateTime', sortOrder: 'desc' });
                    if (inputDTO.shipmentUuid) {
                        shipmentService.getShipmentLogs(dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                        shipmentService.getNotes(dispatch, lang, dictionary, inputDTO)
                    }
                }
                utilCommon.showNotification(response.data.message, dictionary, lang);
                if (inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
                    getNotes(dispatch, lang, dictionary, {
                        ...inputDTO,
                        sortColumn: 'addedDate',
                        sortOrder: 'desc',
                        pageNumber: 1,
                        pageSize: 10
                    })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

//  update carrier
export const updateCarrier = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'carrier');
    dispatch(actions.setLoader(true))
    axios['post'](url + "?customerUuid=" + inputDTO.customerUuid, inputDTO, params)
        .then((response) => {
            dispatch(actions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getCarrierPreference(dispatch, lang, dictionary, inputDTO)
                if (inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(actions.setLoader(false))
        });
};

// get customer carrier preferences
export const getCarrierPreference = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'carrier');
    dispatch(commonAction.setLoader(true))
    axios.get(url + "?customerUuid=" + inputDTO.customerUuid, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {

                dispatch(actions.setInitialCarrier(response.data.content.carrierPreferenceList, response.data.content.count, false))
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};


// get contract pricing
export const getContractPricing = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'contactPricing');
    url = url + '?customerUuid=' + inputDTO.customerUuid + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber + '&sortColumn=' + inputDTO.sortColumn + '&sortOrder=' + inputDTO.sortOrder
    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_PRICING, specificPricing: response.data.content.specificPricing })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// get customer shipments
export const getCustomerShipments = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'shipment');
    url = url + '?customerUuid=' + inputDTO.customerUuid + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber +'&permFlag=true'
    dispatch({ type: shipmentType.SET_SHIPMENTS, shipments: [], totalCount: 0, append: false })
    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                dispatch({ type: shipmentType.SET_SHIPMENTS, shipments: response.data.content.shipmentList, totalCount: response.data.content.count, append: false })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

//  get carrier
export const getCarrier = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'getCarriers');
    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                dispatch(actions.setCarriers(response.data.content.carrierList, response.data.content.count, false))
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

//  send marketing template to selected email
export const sendEmailTemplate = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'sendEmail');
    dispatch(commonAction.setLoader(true))
    axios[inputDTO.id ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(true))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                if (inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

//  add or  update note
export const addNote = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'note');

    dispatch(commonAction.setLoader(true))
    axios[inputDTO.customerNoteUuid ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getNotes(dispatch, lang, dictionary, inputDTO);
                if (inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};
// get Notes List
export const getNotes = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'note');
    let col = inputDTO.sortColumn
    url = url + '?customerUuid=' + inputDTO.customerUuid + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber + '&sortColumn=' + col + '&sortOrder=' + inputDTO.sortOrder
    if(inputDTO.permFlag){
        url = url +`&permFlag=${inputDTO.permFlag}`
    }
    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                if (inputDTO.keyword && response.data.content.customerNoteList && response.data.content.customerNoteList.length == 0) {
                    // utilCommon.showNotification(9001, dictionary, lang)
                }
                dispatch(actions.setNotes(response.data.content.customerNoteList, response.data.content.count, false))
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        }).catch((err) => { dispatch(commonAction.setLoader(false)) });
};

// update pin or unpin note

export const pinOrUnpinNote = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'note');
    dispatch(commonAction.setLoader(true))
    axios['put'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getNotes(dispatch, lang, dictionary, { ...inputDTO, pageNumber: 1, pageSize: 100 });
                if (inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};
//  update customer preferences
export const updatePreferences = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'preference');
    dispatch(actions.setLoader(true))
    axios['put'](url, inputDTO, params)
        .then((response) => {
            dispatch(actions.setLoader(false))

            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getCustomerData(dispatch, lang, dictionary, inputDTO)
                if (inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(actions.setLoader(false))
        });
};

// get Products
export const getProducts = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'product');

    let col = inputDTO.sortColumn

    url = url + '?customerUuid=' + inputDTO.customerUuid + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber + '&sortColumn=' + col + '&sortOrder=' + inputDTO.sortOrder
    if (inputDTO.keyword) {
        url = url + '&keyword=' + inputDTO.keyword
    }
    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                if (inputDTO.keyword && response.data.content.customerProductList && response.data.content.customerProductList.length == 0) {
                    // utilCommon.showNotification(9001, dictionary, lang)
                }
                dispatch(actions.setProducts(response.data.content.customerProductList, response.data.content.count, false))
                dispatch({
                    type: type.UPDATE_PRODUCT_PAGING, productPageNumber: inputDTO.pageNumber,

                    productPageSize: inputDTO.pageSize,
                    productSortColumn: col,
                    productSortOrder: inputDTO.sortOrder
                })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// get Tasks list
export const getTasks = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'task');
    let col = inputDTO.sortColumn
    url += '?customerUuid=' + inputDTO.customerUuid + '&pageSize=500&pageNumber=1&sortColumn=' + col
        + '&sortOrder=' + inputDTO.sortOrder + '&taskStatus=[1,2,4]'
    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                dispatch(actions.setTasks(response.data.content.userTaskList, response.data.content.count, false))
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// get Logs
export const getLogs = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'logs');
    let col = inputDTO.sortColumn
    url = url + '?pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber + '&sortColumn=' + col + '&sortOrder=' + inputDTO.sortOrder
    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                if (inputDTO.keyword && response.data.content.customerList && response.data.content.customerList.length == 0) {
                    // utilCommon.showNotification(9001, dictionary, lang)
                }
                dispatch(actions.setCustomers(response.data.content.customerList, response.data.content.count, false))
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// get Customr Agents for add or update tasks
export const getAgents = (dispatch, lang, dictionary, inputDTO) => {
    let queryString;
    if (inputDTO) {
        queryString = utilCommon.convertObjectToQueryString(inputDTO);
    }
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'agents');

    if (queryString) {
        url += `?${queryString}`;
    }

    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {

                dispatch(actions.setAgents(response.data.content.userList, response.data.content.count, false))
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

export const getActiveAgents = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'agents');

    url += '?status=1&sortColumn=name&sortOrder=asc';

    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {

                dispatch({ type: type.SET_ACTIVE_AGENTS, activeAgents: response.data.content.userList })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// get Contact Log List
export const getContactLogs = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'contactlogs');
    let col = inputDTO.sortColumn
    url = url + '?customerUuid=' + inputDTO.customerUuid;
    if (inputDTO.pageSize) {
        url = url + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber
    }
    if (inputDTO.sortColumn) {
        url = url + '&sortColumn=' + col + '&sortOrder=' + inputDTO.sortOrder
    }

    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                if (inputDTO.keyword && response.data.content.customerContactLogList && response.data.content.customerContactLogList.length == 0) {
                    // utilCommon.showNotification(9001, dictionary, lang)
                }
                if (inputDTO.pageSize) {
                    dispatch({
                        type: type.UPDATE_CONTACTLOG_PAGING, logPageNumber: inputDTO.pageNumber,

                        logPageSize: inputDTO.pageSize,
                        logSortColumn: col,
                        logSortOrder: inputDTO.sortOrder
                    })
                    dispatch(actions.setContactLogs(response.data.content.customerContactLogList, response.data.content.count, false))
                } else {
                    dispatch(actions.setAllContactLogs(response.data.content.customerContactLogList, response.data.content.count, false))
                }

            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// get Address list
export const getAddresses = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'address');

    let col = inputDTO.sortColumn

    url = url + '?customerUuid=' + inputDTO.customerUuid + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber + '&sortColumn=' + col + '&sortOrder=' + inputDTO.sortOrder
    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                if (inputDTO.keyword && response.data.content.customerAddressList && response.data.content.customerAddressList.length == 0) {
                    // utilCommon.showNotification(9001, dictionary, lang)
                }
                dispatch(actions.setAddresses(response.data.content.customerAddressList, response.data.content.count, false))
                dispatch({
                    type: type.UPDATE_ADDRESS_PAGING, addressPageNumber: inputDTO.pageNumber,

                    addressPageSize: inputDTO.pageSize,
                    addressSortColumn: col,
                    addressSortOrder: inputDTO.sortOrder
                })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

//  delete Address
export const deleteAddress = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'address');
    dispatch(commonAction.setLoader(true))
    axios.patch(url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getAddresses(dispatch, lang, dictionary, inputDTO);
                if (inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

//  delete Product
export const deleteProduct = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'product');

    dispatch(commonAction.setLoader(true))
    axios.patch(url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getProducts(dispatch, lang, dictionary, inputDTO);
                if (inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};
//  Log A contact
export const logContact = (dispatch, lang, dictionary, inputDTO, flag) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('customer', 'contactlogs');
    dispatch(commonAction.setLoader(true))
    return axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getContactLogs(dispatch, lang, dictionary, {
                    ...inputDTO,
                    sortColumn: 'addedDate',
                    sortOrder: 'desc',
                    pageNumber: 1,
                    pageSize: 10
                })
                getNotes(dispatch, lang, dictionary, {
                    ...inputDTO,
                    sortColumn: 'addedDate',
                    sortOrder: 'desc',
                    pageNumber: 1,
                    pageSize: 10
                })

                if (!flag) {
                    getCustomerData(dispatch, lang, dictionary, {customerUuid: inputDTO.customerUuid})
                }

                if (!flag && inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, {id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1})
                }

                if (!lodash.isEmpty(lodash.get(inputDTO, 'followUpDate'))) {
                    getTasks(dispatch, lang, dictionary, { ...inputDTO, sortColumn: 'dueDateTime', sortOrder: 'asc' });
                }

                leadService.getAllLeads(dispatch, lang, dictionary, lodash.get(inputDTO, 'filterParams', {}))
            } else {
                response.data.errors.map((error) => {
                    if (Number.isInteger(error)) {
                        utilCommon.showNotification(error, dictionary, lang)
                    } else {
                        utilCommon.showAgentNotification(error)
                    }
                })
            }
            return response
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

export const getCustomerDetails = (dispatch, currentLanguage, dictionaries, inputDTO) => {
    getMasterData();
    getCustomerData(dispatch, currentLanguage, dictionaries, inputDTO)
    getCarrierPreference(dispatch, currentLanguage, dictionaries, inputDTO)
    getAddresses(dispatch, currentLanguage, dictionaries, { ...inputDTO, sortOrder: 'desc', sortColumn: 'customerAddress1', pageSize: 100, pageNumber: 1 })
    getProducts(dispatch, currentLanguage, dictionaries, { ...inputDTO, sortOrder: 'desc', sortColumn: 'addedDate', pageSize: 100, pageNumber: 1 })
    getNotes(dispatch, currentLanguage, dictionaries, { ...inputDTO, customerUuid: inputDTO.customerUuid, pageSize: 100, pageNumber: 1, sortColumn: 'addedDate', sortOrder: 'desc' })
    getContactLogs(dispatch, currentLanguage, dictionaries, { ...inputDTO, sortOrder: 'desc', sortColumn: 'addedDate', pageSize: 10, pageNumber: 1 })
    getTemplates(dispatch, currentLanguage, dictionaries, inputDTO)
    getCustomers(dispatch, currentLanguage, dictionaries, {pageNumber:1,pageSize:100,sortColumn:'customerName',sortOrder:'asc'})
    getAgents(dispatch, currentLanguage, dictionaries, inputDTO)
    getCarrier(dispatch, currentLanguage, dictionaries, inputDTO)
    // let payload = { customerUuid: (this.props.location.state ? this.props.location.state.customerUuid : this.props.location.pathname.split('/')[3]), pageNumber: 1, pageSize: 10 }
    getTasks(dispatch, currentLanguage, dictionaries, {...inputDTO, pageNumber:1,pageSize:100,sortColumn:'dueDateTime',sortOrder:'asc'})
    getPaymentOptions(dispatch, currentLanguage, dictionaries, {...inputDTO, customerUuid: inputDTO.customerUuid, pageNumber: 1, pageSize: 50 })
    getCustomerShipments(dispatch, currentLanguage, dictionaries, {...inputDTO, customerUuid: inputDTO.customerUuid, pageNumber: 1, pageSize: 50})
    getContractPricing(dispatch, currentLanguage, dictionaries, { ...inputDTO, customerUuid: inputDTO.customerUuid, pageSize: 100, pageNumber: 1, sortColumn: 'contractName', sortOrder: 'asc' })
}


//  merge customer
export const mergeCustomer = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'merge');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then(response => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                // dispatch({ type: type.SET_MERGE_CUSTOMER, productPageNumber: 1, productPageSize: 10, productSortColumn: 'addedDate', productSortOrder: 'desc'})
                getCustomerDetails(dispatch, lang, dictionary, {...inputDTO, customerUuid: inputDTO.sourceCustomerUuid});
                getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.sourceCustomerUuid, pageSize: 10, pageNumber: 1 })
                getARPaymentOptions(dispatch, lang, dictionary, {...inputDTO, customerUuid: inputDTO.sourceCustomerUuid})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang, true)
                })
            }
        }).catch((err) => {
            utilCommon.showNotification(err, dictionary, lang, true)
            dispatch(commonAction.setLoader(false))
        });
};
// get customer intial Data

export const getCustomerData = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'customer');
    dispatch(commonAction.setLoader(true))
    if (!inputDTO.customerUuid) return;
    url = url + "?customerUuid=" + inputDTO.customerUuid
    if(inputDTO.permFlag){
        url = url + "&permFlag=" + inputDTO.permFlag
    }
    axios.get(url , params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                dispatch(actions.setCurrentCustomer(response.data.content.customerList[0], response.data.content.count, false))
            } else {
                if (lodash.get(inputDTO, 'rewardPointsCall', false)) dispatch(actions.setCurrentCustomer({}, 0, false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};
// forgot password
export const resetPassword = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'forgotPassword');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                if (inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// add card
export const addCardDetails = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() }
    const url = getApiUrl('customer', 'payment')

    dispatch(commonAction.setLoader(true))
    axios[inputDTO.customerPaymentProfileId ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                if (inputDTO.customerPaymentProfileId) {
                    utilCommon.showNotification('Payment options updated succesfully.', dictionary, lang)
                } else {
                    utilCommon.showNotification('Payment options added succesfully.', dictionary, lang)
                }
                if (inputDTO.dontSaveInProfile != undefined && inputDTO.dontSaveInProfile == "1") {
                    dispatch({ type: shipmentType.SET_LOCALCARDS, card: { ...inputDTO, localCard: true, paymentProfileId: response.data.content.paymentProfileId } })
                }
                getPaymentOptions(dispatch, lang, dictionary, { customerUuid: inputDTO.customerUuid.id, flag: true })
                if (inputDTO.customerUuid.id) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid.id, pageSize: 10, pageNumber: 1 })
                    getARPaymentOptions(dispatch, lang, dictionary, { customerUuid: inputDTO.customerUuid.id, flag: true })
                    getNotes(dispatch, lang, dictionary, {
                        customerUuid: inputDTO.customerUuid.id,
                        sortColumn: 'addedDate',
                        sortOrder: 'desc',
                        pageNumber: 1,
                        pageSize: 10
                    })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang, true)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        })
}

// delete card
export const deletePaymentCard = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() }
    const url = getApiUrl('customer', 'payment')
    dispatch(commonAction.setLoader(true))
    axios.delete(url, { ...params, data: { customerPaymentProfileUuid: inputDTO.customerPaymentProfileUuid } })
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Payment option deleted succesfully.', dictionary, lang)
                getPaymentOptions(dispatch, lang, dictionary, { customerUuid: inputDTO.customerUuid, flag: true })
                if (inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang, true)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        })
}

// get payment options
export const getPaymentOptions = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'payment');
    dispatch(commonAction.setLoader(true))
    url = url + "?customerUuid=" + inputDTO.customerUuid + '&sortColumn=modifiedDate&sortOrder=desc&deleted=1'
    if(inputDTO.permFlag){
        url = url + "&permFlag=" + inputDTO.permFlag
    }
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                let result = lodash.orderBy(response.data.content.customerCreditCardsListing, ['customerPaymentProfileStatus', 'addedDate'],['asc', 'desc'])
                result = lodash.filter(result, card => !utilCommon.isCardExpired(card.expireDate.substr(0,2), card.expireDate.substr(-2)))
                dispatch({ type: type.SET_PAYMENT_OPTIONS, customerCreditCardsListing: result, flag: inputDTO.flag })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};


// AR Payment
export const paymentAccountReceivable = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() }
    const url = getApiUrl('customer', 'paymentAR')
    dispatch(commonAction.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                if (inputDTO.customerPaymentProfileId) {
                    utilCommon.showNotification('Payment option updated succesfully.', dictionary, lang)
                } else {
                    utilCommon.showNotification('Payment option added succesfully.', dictionary, lang)
                }
                getARPaymentOptions(dispatch, lang, dictionary, { customerUuid: inputDTO.customerUuid, flag: true })
                getCustomerData(dispatch, lang, dictionary, { customerUuid: inputDTO.customerUuid })
                if (inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        })
}

export const updateCreditApp = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() }
    const url = getApiUrl('customer', 'creditApp');
    dispatch(commonAction.setLoader(true));

    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false));

            if (response.data.count) {
                utilCommon.showNotification('Credit app updated successfully.', dictionary, lang);
                getCustomerData(dispatch, lang, dictionary, { customerUuid: inputDTO.customerUuid });
            }
        })
        .catch((err) => {
            utilCommon.showNotification(err, dictionary, lang, true);
            dispatch(commonAction.setLoader(false));
        });
}

// get payment options
export const getARPaymentOptions = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'paymentAR');
    dispatch(commonAction.setLoader(true))

    url = url + "?customerUuid=" + inputDTO.customerUuid
    if(inputDTO.permFlag){
        url = url +`&permFlag=${inputDTO.permFlag}`
    }
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_PAYMENT_AR_OPTIONS, customerARInfo: response.data.content.arInfo, flag: inputDTO.flag })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};
// delete task
export const deleteTask = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() }
    const url = getApiUrl('customer', 'task')
    dispatch(commonAction.setLoader(true))
    axios.patch(url, { userTaskUuid: inputDTO.userTaskUuid, taskStatus: { id: 0, name: 'Deleted' } }, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Task is deleted successfully.', dictionary, lang)
                getTasks(dispatch, lang, dictionary, { customerUuid: inputDTO.customerUuid, sortColumn: 'dueDateTime', sortOrder: 'asc' })
                if (inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang, true)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        })
}

//  reconcile a contact
export const reconcileTask = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'task');
    dispatch(commonAction.setLoader(true))
    axios['patch'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getContactLogs(dispatch, lang, dictionary, { ...inputDTO, sortColumn: 'addedDate', sortOrder: 'desc', pageNumber: 1, pageSize: 10 })
                getCustomerData(dispatch, lang, dictionary,{customerUuid:inputDTO.customerUuid})
                if (inputDTO.userTaskUuid) {
                    getTasks(dispatch, lang, dictionary, { ...inputDTO, sortColumn: 'dueDateTime', 'sortOrder': 'asc' })
                }
                if (inputDTO.customerUuid) {
                    getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

//  rea assign lead
export const assignNewLead = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'assignNewLead');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Lead assigned successfully.', dictionary, lang)
                leadService.getAllLeads(dispatch, lang, dictionary, { customerUuid: inputDTO.customerUuid})
                getCustomerData(dispatch, lang, dictionary, { customerUuid: inputDTO.customerUuid})
                getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};


//  rea assign lead
export const getAgentList = (dispatch, lang, dictionary) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'agentsList');
    dispatch(commonAction.setLoader(true))
    axios.get(url,params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
               dispatch({ type : type.SET_ASSIGNEE_AGENTS , agentList : response.data.content })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// get email templete list

export const getTemplates = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'getTemplates');
    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_TEMPLATES, templateList: response.data.content.emailTemplateList})
            } else {
                response.data.errors.map((error) => {
                    if (error == 1059) {
                        return;
                    }
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// get email templete list

export const getPreviewTemplate = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'getPreviewTemplate');
    url = url + '?templateId='+inputDTO.templateId+'&versionId='+inputDTO.versionId
    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_EMAIL_TEMPLATE_PREVIEW, templatePreview: response.data.content.emailTemplateList})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};


//  send marketing templates
export const sendMarketingEmailTemplate = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'sendMarketingTemplate');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

//  update lead source in marketing
export const updateLeadSource = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'customer');
    dispatch(actions.setLoader(true))
    axios['patch'](url, inputDTO, params)
        .then((response) => {
            dispatch(actions.setLoader(false))
            if (response.data.success) {
                getCustomerData(dispatch, lang, dictionary, { customerUuid: inputDTO.customerUuid })
                utilCommon.showNotification(response.data.message, dictionary, lang)

            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(actions.setLoader(false))
        });
};

export const deleteAllProducts = (dispatch, lang, dictionary, inputDTO)=>{
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'deleteProduct');
    dispatch(commonAction.setLoader(true))
    axios['patch'](url, {customerUuid : inputDTO.customerUuid}, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                uploadBulkProducts(dispatch, lang, dictionary, inputDTO)
            } else {
                    dispatch({ type: type.PRODUCT_BULK_UPLOADED, bulkProductUploaded: true, bulkProductError: [] })
                    utilCommon.showNotification('Cannot upload due to error in deletion of existing records', dictionary, lang)
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
}

// add or update product
export const uploadBulkProducts = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'product');
    dispatch(commonAction.setLoader(true))
    let promises = []
    inputDTO.products.map((x,index)=>{
        promises[index] = axios['post'](url, x, params)
    })
    axios.all(promises)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            let successCase = 0;
            let errRows = []
            response.map((x,index)=>{
                if(x.data.success){
                    successCase ++;
                } else{
                    errRows.push(index+1)
                }
            })
            if(successCase == inputDTO.products.length){
                utilCommon.showNotification('Products are imported from excel successfully', dictionary, lang)
            }
            dispatch({ type: type.PRODUCT_BULK_UPLOADED, bulkProductUploaded: true, bulkProductError: errRows })
            getProducts(dispatch, lang, dictionary, { ...inputDTO, pageNumber: 1, sortColumn: 'addedDate', sortOrder: 'desc', pageSize: 10 });
            dispatch({
                type: type.UPDATE_PRODUCT_PAGING, productPageNumber: 1,
                productPageSize: 10,
                productSortColumn: 'addedDate',
                productSortOrder: 'desc'
            })

            if (inputDTO.customerUuid) {
                getCustomerLogs(dispatch, lang, dictionary, { id: inputDTO.customerUuid, pageSize: 10, pageNumber: 1 })
            }
        })
        .catch((err) => {
            dispatch({ type: type.PRODUCT_BULK_UPLOADED, bulkProductUploaded: true, bulkProductError: [] })
            dispatch(commonAction.setLoader(false))
        });
};

//Reassign customer
export const reassignCustomers = (dispatch, lang, dictionary, inputDTO, pagingParam) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('customer', 'customer');
    dispatch(commonAction.setLoader(true))
    axios['patch'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                response.data.message = 'Customers reassigned successfully';
                utilCommon.showNotification(response.data.message, dictionary, lang)
                dispatch({type: type.SET_REASSIGN_FLAG, reassignFlag: true})
                getCustomers(dispatch, lang, dictionary, {...pagingParam});
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

export const getEmailTemplatesList = (dispatch, lang, dictionary, queryParams) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('emailTemplates', 'getEmailTemplatesList');

    if (queryParams) {
        url = `${url}?${buildQueryString(queryParams)}`
    }

    dispatch(commonAction.setLoader(true))

    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))

            if (response.data.success) {
                dispatch({type: SET_CUSTOMER_EMAIL_TEMPLATES, emailTemplates: response.data.data});
            } else {
                response.data.errors.map((error) => {
                    if (error == 1059) {
                        return;
                    }
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
}

export const reindexCustomer = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('customer', 'reindex');

    dispatch(commonActions.setLoader(true));

    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Customer has been re-indexed', dictionary, lang);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showAgentNotification(error)
                })
            }
        }).catch(err => dispatch(commonActions.setLoader(false)));
}

export default {
    addCustomer,
    getCarrier,
    addNote,
    getNotes,
    pinOrUnpinNote,
    getMasterData,
    getCustomers,
    getCustomerData,
    updateDetailedInformation,
    addOrUpdateAddress,
    deleteAddress,
    deleteProduct,
    addOrUpdateTask,
    updateCarrier,
    getCarrierPreference,
    sendEmailTemplate,
    updatePreferences,
    getProducts,
    getTasks,
    getAgents,
    getActiveAgents,
    getAddresses,
    getLogs,
    getContactLogs,
    logContact,
    mergeCustomer,
    addOrUpdateProduct,
    resetPassword,
    getContractPricing,
    getCustomerShipments,
    getCustomerLogs,
    addCardDetails,
    getPaymentOptions,
    paymentAccountReceivable,
    updateCreditApp,
    getARPaymentOptions,
    deletePaymentCard,
    deleteTask,
    reconcileTask,
    assignNewLead,
    getAgentList,
    getTemplates,
    getPreviewTemplate,
    sendMarketingEmailTemplate,
    updateLeadSource,
    uploadBulkProducts,
    deleteAllProducts,
    reassignCustomers,
    getCustomerDetails,
    getEmailTemplatesList
};


